// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.redfont{
    color: red;
}

.row.odd{
    background-color:#d9dce0;
}

.row.even{
    background-color:#f7faff;
}

.solvay-label{
    background-color: #e9ecef;
}

.label-quota{
    color:blue;
}

.past-quota-year{
    color:grey;
}

.current-quota-year{
    color:blue;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
