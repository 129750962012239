// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputtext{
    width:250px; margin:5px; text-indent:10px;
}

.dropdown{
    width:250px; margin:5px;
}

.solvay-label{
    background-color: #e9ecef;
}

.label-quota{
    color:blue;
}

.past-quota-year{
    color:grey;
}

.current-quota-year{
    color:blue;
}
input[type=checkbox] {
    transform: scale(1.5);
    -ms-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    padding: 5px;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
